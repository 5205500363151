import { GLDetailedModal } from "@group-link-one/grouplink-components";

import { GLPaginationProvider } from "../../../contexts/GLPaginationContext/GLPaginationContext";
// import { PopupPreviewContent } from "../Notifications/PopupPreview/PopupPreviewContent/PopupPreviewContent";
import { ModalCreateNotificationContent } from "./ModalContent/modalContent";
import { useModalCreateNotification } from "./useModalCreateNotification";

export function ModalCreateNotification() {
  const {
    refDetailedModal,
    createNotificationActions,
    createNotificationState,
    resetAll,
    lastNextPageToken,
    tabs
  } = useModalCreateNotification();

  return (
    <GLPaginationProvider tabs={tabs} lastNextPageToken={lastNextPageToken}>
      <GLDetailedModal
        ref={refDetailedModal}
        open={createNotificationState.modalCreateNotificationIsOpen}
        setIs={(open) => {
          createNotificationActions.setModalCreateNotificationIsOpen(open);
          resetAll();
        }}
        title="Create Notification"
        justifyContent="flex-start"
        width="100%"
        content={<ModalCreateNotificationContent />}
      />

      {/* <GLModal
        hasButtonClose={true}
        onClose={() => selectTemplateActions.setModalPreviewNotificationIsOpen(false)}
        open={selectTemplateState.modalPreviewNotificationIsOpen}
        maxWidth="fit-content"
        title="Choose priority and preview message"
        content={
          <PopupPreviewContent />
        } */}
      {/* /> */}
    </GLPaginationProvider>

  );
}
