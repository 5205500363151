import {
  GL_COLORS,
  GLBox,
  GLCardTemplate,
  GLTypography,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { DateTime } from "luxon";

import { GetEventListResponse } from "../../../Services/eventListService/useEventListService.types";

export const CardsDesktop = ({ events }: any) => {
  const { t } = useI18n();

  function formatTime(timestamp: number): string {
    const dt = DateTime.fromMillis(timestamp, { zone: "utc" });

    if (dt.hour > 0) return dt.toFormat("HH'h 'mm'm'");

    if (dt.minute > 0) return dt.toFormat("mm'm 's's'");

    if (dt.second > 0) return dt.toFormat("s's");

    if (dt.millisecond > 0) return dt.toFormat("SSS") + "ms";

    return "--";
  }

  return (
    <>
      {events?.map((col: GetEventListResponse) => (
        <GLCardTemplate.Provider
          key={col.deviceId + col.deviceName + col.eventCreated + Math.random()}
        >
          <GLCardTemplate.Root borderRadius="0px">
            <GLCardTemplate.Header>
              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography
                  text={String(
                    format(col.eventCreated, "dd/MM/yyyy - HH:mm:ss")
                  )}
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography
                  text={String(format(col.eventSent, "dd/MM/yyyy - HH:mm:ss"))}
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                align="center"
                style={{
                  display: "flex",
                  borderRadius: "0px 10px",
                }}
                justify="flex-start"
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50%",
                    backgroundColor: formatTime(col.latency).includes("ms")
                      ? GL_COLORS.SUCCESS
                      : formatTime(col.latency).includes("s")
                        ? GL_COLORS.WARNING
                        : GL_COLORS.DANGER,
                  }}
                ></div>

                <GLTypography
                  text={formatTime(col.latency)}
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography text={col.deviceId} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography text={col.userId} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography text={col.model} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography
                  text={
                    col.eventType === "EVENT_IN"
                      ? t("eventList.columns.eventType.entry")
                      : t("eventList.columns.eventType.exit")
                  }
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                {col.eventDuration ? (
                  <GLTypography
                    text={formatTime(Number(col.eventDuration))}
                    color={"FONT_COLOR"}
                  />
                ) : (
                  "--"
                )}
              </GLCardTemplate.Column>
            </GLCardTemplate.Header>
          </GLCardTemplate.Root>
        </GLCardTemplate.Provider>
      ))}
    </>
  );
};

export const CardsMobile = ({ events }: any) => {
  const { t } = useI18n();

  function formatTime(timestamp: number): string {
    const dt = DateTime.fromMillis(timestamp, { zone: "utc" });

    if (dt.hour > 0) return dt.toFormat("HH'h 'mm'm'");

    if (dt.minute > 0) return dt.toFormat("mm'm 's's'");

    if (dt.second > 0) return dt.toFormat("s's");

    if (dt.millisecond > 0) return dt.toFormat("SSS") + "ms";

    return "--";
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          padding: "15px",
        }}
      >
        {events?.map((col: GetEventListResponse) => (
          <GLCardTemplate.Provider
            key={
              col.deviceId + col.deviceName + col.eventCreated + Math.random()
            }
          >
            <GLCardTemplate.Root borderRadius="10px">
              <GLCardTemplate.Header padding={"15px"} justify="space-between">
                <GLCardTemplate.Column direction="column">
                  <GLTypography
                    fontSize={3}
                    text={t("eventList.columns.device_id")}
                    color="FONT_COLOR_DARK_GREY"
                    weight={600}
                  />
                  <GLTypography fontSize={3} text={col.deviceId} weight={600} />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column direction="column">
                  <GLTypography
                    fontSize={3}
                    text={t("eventList.columns.created_at")}
                    color="FONT_COLOR_DARK_GREY"
                    weight={600}
                  />
                  <GLTypography
                    fontSize={3}
                    text={String(
                      format(col.eventCreated, "dd/MM/yyyy - HH:mm:ss")
                    )}
                  />
                </GLCardTemplate.Column>

                <GLCardTemplate.Arrow />
              </GLCardTemplate.Header>

              <GLCardTemplate.Content
                style={{ backgroundColor: GL_COLORS.BACKGROUND_SECONDARY }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                  }}
                >
                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.sent")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      fontSize={3}
                      text={String(
                        format(col.eventSent, "dd/MM/yyyy - HH:mm:ss")
                      )}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.latency")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />

                    <GLBox align="center">
                      <div
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          backgroundColor: formatTime(col.latency).includes(
                            "ms"
                          )
                            ? GL_COLORS.SUCCESS
                            : formatTime(col.latency).includes("s")
                              ? GL_COLORS.WARNING
                              : GL_COLORS.DANGER,
                        }}
                      ></div>

                      <GLTypography
                        text={formatTime(col.latency)}
                        color={"FONT_COLOR"}
                        fontSize={3}
                      />
                    </GLBox>
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.duration")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    {col.eventDuration ? (
                      <GLTypography
                        text={formatTime(Number(col.eventDuration))}
                        color={"FONT_COLOR"}
                        fontSize={3}
                      />
                    ) : (
                      "--"
                    )}
                  </GLCardTemplate.Column>
                </div>

                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                  }}
                >
                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.user_id")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      text={col.userId}
                      color={"FONT_COLOR"}
                      fontSize={3}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.model")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      text={col.model}
                      color={"FONT_COLOR"}
                      fontSize={3}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.type")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      text={col.eventType === "EVENT_IN" ? "In" : "Out"}
                      color={"FONT_COLOR"}
                      fontSize={3}
                    />
                  </GLCardTemplate.Column>
                </div>
              </GLCardTemplate.Content>
            </GLCardTemplate.Root>
          </GLCardTemplate.Provider>
        ))}
      </div>
    </>
  );
};
