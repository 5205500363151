import {
  GL_CHECKIN,
  GL_REVIEW,
  GL_SEND_MESSAGE,
  StepProps,
} from "@group-link-one/grouplink-components";
import { create } from "zustand";

interface CreateNotificationStoreState {
  modalCreateNotificationIsOpen: boolean;
  steperIsOnTop: boolean;
  stepActive: number;
  steps: StepProps[];
}

interface CreateNotificationStoreActions {
  setModalCreateNotificationIsOpen: (isOpen: boolean) => void;
  setSteperIsOnTop: (isOnTop: boolean) => void;
  setStepActive: (step: number) => void;

  setStepWasVisited: (step: keyof typeof NEW_NOTIFICATION_STEP_NAMES_TO_KEYS) => void;
  toStepOnClickInEdit: (step: keyof typeof NEW_NOTIFICATION_STEP_NAMES_TO_KEYS) => void;

  resetAll: () => void;
}

interface UsersStore {
  state: CreateNotificationStoreState;
  actions: CreateNotificationStoreActions;
}

export enum NEW_NOTIFICATION_STEP_NAMES_TO_KEYS {
  SEGMENTATION = 0,
  SELECT_TEMPLATE = 1,
  REVIEW = 2,
}

export const useCreateNotificationStore = create<UsersStore>((set) => ({
  state: {
    modalCreateNotificationIsOpen: false,
    stepActive: 0,
    steperIsOnTop: false,
    steps: [
      {
        key: "segmentation",
        Icon: <GL_CHECKIN />,
        title: "Segmentation",
        wasVisited: true,
      },
      {
        key: "select-template",
        Icon: <GL_SEND_MESSAGE />,
        title: "Select template",
        textToHere: "Continue to template",
        wasVisited: false,
      },
      {
        key: "review-template",
        Icon: <GL_REVIEW />,
        title: "Message preview",
        textToHere: "Continue to review",
        wasVisited: false,
      },
      // {
      //   key: "schedule",
      //   title: "Schedule",
      //   Icon: <GL_CALENDAR />,
      //   textToHere: "Continue to scheduling",
      //   wasVisited: false,
      // },
      // {
      //   key: "review",
      //   title: "Review",
      //   Icon: <GL_REVIEW />,
      //   textToHere: "Continue to review",
      //   wasVisited: false,
      // },
    ].map((_, i) => ({ ..._, id: i })),
  },
  actions: {
    setModalCreateNotificationIsOpen: (isOpen: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          modalCreateNotificationIsOpen: isOpen,
        },
      }));
    },

    setSteperIsOnTop: (isOnTop: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          steperIsOnTop: isOnTop,
        },
      }));
    },

    setStepActive: (step: number) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive: step,
        },
      }));
    },

    setStepWasVisited: (stepName: keyof typeof NEW_NOTIFICATION_STEP_NAMES_TO_KEYS) => {
      set((state) => ({
        state: {
          ...state.state,
          steps: state.state.steps.map((step) => {
            if (step.id === NEW_NOTIFICATION_STEP_NAMES_TO_KEYS[stepName]) {
              return {
                ...step,
                wasVisited: true,
              };
            }

            return step;
          }),
        },
      }));
    },

    toStepOnClickInEdit: (stepName: keyof typeof NEW_NOTIFICATION_STEP_NAMES_TO_KEYS) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive: NEW_NOTIFICATION_STEP_NAMES_TO_KEYS[stepName],
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          modalCreateNotificationIsOpen: false,
          stepActive: 0,
          steperIsOnTop: false,
          steps: state.state.steps.map((step) => ({
            ...step,
            wasVisited: step.id === 0,
          })),

        },
      }));
    },
  },
}));
