import { useAuth } from "@group-link-one/gl-auth";
import { useGLModalLogout } from "@group-link-one/grouplink-components";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const useAuthServices = () => {
  const { handleOpenModalLogout } = useGLModalLogout();
  const { logOut, accessToken } = useAuth()

  async function handleLogout(accessTokenParam?: string) {
    try {
      if (!accessTokenParam) return
      await logOut(accessTokenParam);

    } catch (error) {

      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          handleOpenModalLogout();
        }
      }
    }
  }

  const { mutateAsync: logoutFn } = useMutation<any, any, { session_id?: string }>({
    mutationFn: () => handleLogout(accessToken),
    // onSuccess: () => {
    //   // callbackFnToModalLogout();
    // }
  })


  // const { mutateAsync: logoutSessionFn } = useMutation<any, any, { session_id?: string }>({
  //   mutationFn: () => handleLogout(accessToken),
  //   // onSuccess: () => {
  //   //   // callbackFnToModalLogout();
  //   // }
  // })

  return {
    logOut: logoutFn,
  };
};
