import { useQuery } from "@tanstack/react-query"

import { useGLPagination } from "../../../../contexts/GLPaginationContext/GLPaginationContext"
import { useListNotifications } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications"

export const IPP_NOTIFICATIONS = 10

export const useNotificationsContent = () => {
  const {
    state: { tabs, activeTabId, currentPage },
    actions: { getActiveTabById, setCurrentPage, tabsActions, }
  } = useGLPagination()

  const { getNotificationsDelivered } = useListNotifications()
  const activeTab = getActiveTabById(activeTabId || 1)

  const { data: notificationsDelivereds, isLoading: notificationsDeliveredsIsLoading } = useQuery({
    queryKey: ["get-message-center-notifications-delivered"], // , currentPage, search -> terá mais um parâmetro de filtro quando entrar as outras tabs
    queryFn: async () => {

      // const { nextPageToken, optionsToStoreNextPageToken } = getNextPageToken()

      const response = await getNotificationsDelivered()
      tabsActions.setCount(tabs[0], response.rows.length)
      // setNextPageToken({
      //   hasMore: response.has_more,
      //   nextPageToken: response.next_page_token,
      //   optionsToStoreNextPageToken,
      // })

      return response.rows.filter((notification) => notification.key.includes("notification-delivered-")) || []
    },
    staleTime: 1000 * 60,
  })

  function handleOnPaginate(page: number) {
    setCurrentPage(page)
  }

  return {
    tabs,
    activeTab,
    notificationsDelivereds,
    currentPage,
    notificationsDeliveredsIsLoading,
    handleOnPaginate,
  }
}
