import { GLOnboardTemplate } from "@group-link-one/grouplink-components";
import { Outlet } from "react-router-dom";

import LogoDarkInfoAir from "../../images/Common/logo-dark.svg";
import LogoLightInfoAir from "../../images/Common/logo-light.svg";
import InfoAirArt from "../../images/Onboard/onboard-left-image-desk.png";
import SmartRetailArtMob from "../../images/Onboard/onboard-left-image-mob.png";

export function OnboardLayout() {
  return (
    <GLOnboardTemplate
      images={{
        leftImageDesk: InfoAirArt,
        leftImageMob: SmartRetailArtMob,
        logoLight: LogoLightInfoAir,
        logoDark: LogoDarkInfoAir
      }}
      content={
        <Outlet />
      }
      debug={false}
    />
  );
}
