import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const SegmentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .segmentation-content {
    display: grid;
    grid-template-columns: 0.45fr 0.55fr;
    min-height: 600px;

    border: 1px solid ${GL_COLORS.BORDER_COLOR};
    border-radius: 16px;
    overflow: hidden;

    .segmentation-location {
      padding: 30px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      .location-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .location-list {
        flex: 1;

        .list-content {
          max-height: 350px;
          overflow-y: auto;
        }
      }
    }

    .segmentation-map {
      .gl-map-container {
        transform: scale(0.8);
      }
    }
  }
`;
