import { GL_CHECK, GL_COLORS, GLAccordion, GLCardTemplate, GLTypography, SkeletonBox, StyledAnim } from "@group-link-one/grouplink-components";
import { AnimatePresence, motion } from "framer-motion";
import { List } from "react-virtualized";

import { ListMunicipalitiesContainer } from "./ListMunicipalitiesStyle";
import { useListMunicipalities } from "./useListMunicipalities";

export function ListMunicipalities() {

  const {
    segmentationState,
    onClickInMunicipality,
    onOpenAccordionState,
    statesBRA,
    statesBRAIsLoading,
    getAllMuciipalitiesByStateId,
    municipalitiesInStateActive,
    municipalitiesInStateActiveIsLoading
  } = useListMunicipalities()

  return (
    <ListMunicipalitiesContainer>
      <GLAccordion.Provider>
        <GLAccordion.Root
          style={{
            border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
            borderRadius: 10,
            overflow: "initial",
          }}>

          <GLAccordion.Item
            style={{
              maxHeight: 500,
              overflowY: "auto",
              scrollbarWidth: "none"
            }}
          >
            <GLAccordion.ItemHeader dataId="BR" style={{ pointerEvents: "none" }}>

              <GLCardTemplate.Column direction="column" gap={2}>
                <GLTypography as="p" text="Brasil" fontSize={4} color="FONT_COLOR" weight={600} />
                <GLTypography as="span" text="26 states + 1 federal district" fontSize={3} color="FONT_COLOR_DARK_GREY" weight={400} />
              </GLCardTemplate.Column>

            </GLAccordion.ItemHeader>

            <GLAccordion.ItemContent
              dataId="BR"
              openned
              style={{
                backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                maxHeight: 600,
                overflowY: "auto",
                scrollbarWidth: "none",
              }}
            >
              <GLAccordion.Provider>
                <GLAccordion.Root
                  idSelected={segmentationState.stateSelected}
                  style={{
                    backgroundColor: GL_COLORS.BACKGROUND_SECONDARY
                  }}
                >
                  {!statesBRAIsLoading && statesBRA && statesBRA.map((state) => (
                    <GLAccordion.Item
                      key={state.division.id}
                      idSelected={segmentationState.stateClicked}
                    >
                      <GLAccordion.ItemHeader
                        dataId={state.code}
                        onActionClick={() => onOpenAccordionState(state.code, state.division.id)}
                        style={{
                          paddingLeft: 40,
                        }}
                      >
                        <GLCardTemplate.Column direction="row" gap={5} align="center">
                          <GLTypography as="p" text={state.state_name} fontSize={4} color="FONT_COLOR" weight={600} />
                          <GLTypography
                            as="span"
                            text={`( ${getAllMuciipalitiesByStateId(state.division.id)} )`}
                            fontSize={3}
                            color="FONT_COLOR_DARK_GREY"
                            weight={400}
                          />
                        </GLCardTemplate.Column>
                      </GLAccordion.ItemHeader>

                      <GLAccordion.ItemContent dataId={state.code}>
                        <AnimatePresence mode="wait">
                          {!municipalitiesInStateActiveIsLoading && municipalitiesInStateActive && (
                            <motion.div
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: -20 }}
                              transition={{
                                delay: 0.3,
                              }}
                            >
                              <List
                                className="accordion-list-municipalities"
                                height={300}
                                rowCount={getAllMuciipalitiesByStateId(state.division.id)}
                                rowHeight={45}
                                width={400}
                                style={{
                                  transition: "all .4s",
                                }}
                                autoWidth
                                rowRenderer={({ key, index, style }) => {
                                  const municipality = municipalitiesInStateActive[index]?.division_name;

                                  return (
                                    <div
                                      key={key}
                                      onClick={(e) => onClickInMunicipality(e, municipality, index)}
                                      onKeyUp={() => { }}
                                      role="button"
                                      tabIndex={0}
                                      className={segmentationState?.municipalitiesSelected?.includes(municipality) ? "active" : ""}
                                    >
                                      <GLCardTemplate.Column
                                        align="center"
                                        gap={10}
                                        style={{
                                          ...style,
                                          paddingLeft: "80px",
                                        }}
                                      >
                                        <AnimatePresence mode="wait">
                                          {segmentationState?.municipalitiesSelected?.includes(municipality) && (
                                            <motion.div
                                              initial={{ opacity: 0, x: -20 }}
                                              animate={{ opacity: 1, x: 0 }}
                                              exit={{ opacity: 0, x: -20 }}
                                            >
                                              <GL_CHECK fill={GL_COLORS.ACCENT_COLOR} />
                                            </motion.div>
                                          )}
                                        </AnimatePresence>

                                        <motion.div layout>
                                          <GLTypography
                                            as="p"
                                            text={municipality}
                                            fontSize={4}
                                            color="FONT_COLOR"
                                            weight={400}
                                          />
                                        </motion.div>
                                      </GLCardTemplate.Column>
                                    </div>
                                  )
                                }}
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>

                        {municipalitiesInStateActiveIsLoading &&
                          <GLCardTemplate.Column direction="column" gap={10} style={{ paddingLeft: 60, paddingRight: 20 }}>
                            {Array.from({ length: 10 }).map((_, index) => (
                              <SkeletonBox
                                key={index}
                                style={{
                                  width: "100%",
                                  height: 45,
                                  borderRadius: 6,
                                  border: `1px solid ${GL_COLORS.BORDER_COLOR}`
                                }}
                              >
                                <StyledAnim />
                              </SkeletonBox>
                            ))}
                          </GLCardTemplate.Column>
                        }
                      </GLAccordion.ItemContent>
                    </GLAccordion.Item>
                  ))}

                  {statesBRAIsLoading && (
                    <GLCardTemplate.Column direction="column" style={{ marginTop: 10 }}>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <SkeletonBox
                          key={index}
                          style={{
                            width: "96%",
                            height: 45,
                            borderRadius: 6,
                            marginInline: "auto",
                            border: `1px solid ${GL_COLORS.BORDER_COLOR}`
                          }}
                        >
                          <StyledAnim />
                        </SkeletonBox>
                      ))}
                    </GLCardTemplate.Column>
                  )}
                </GLAccordion.Root>
              </GLAccordion.Provider>
            </GLAccordion.ItemContent>
          </GLAccordion.Item>

        </GLAccordion.Root>
      </GLAccordion.Provider>
    </ListMunicipalitiesContainer>
  );
}
