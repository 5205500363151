import { create } from "zustand";

interface SelectTemplateStoreState {
  messageSelectedId: string | undefined;
  modalPreviewNotificationIsOpen: boolean;

  templateSelectedId: string | undefined;
  typeList: "grid" | "list";
}

interface SelectTemplateStoreActions {
  setMessageSelectedId: (messageSelectedId: string) => void;
  setModalPreviewNotificationIsOpen: (modalPreviewNotificationIsOpen: boolean) => void;
  setTypeList: (typeList: "grid" | "list") => void;
  setTemplateSelectedId: (templateSelectedId: string) => void;
  resetAll: () => void;
}

interface SelectTemplateStore {
  state: SelectTemplateStoreState;
  actions: SelectTemplateStoreActions;
}

export const useSelectTemplateStore = create<SelectTemplateStore>((set) => ({
  state: {
    messageSelectedId: undefined,
    modalPreviewNotificationIsOpen: false,
    typeList: "list",
    templateSelectedId: undefined,
  },
  actions: {
    setMessageSelectedId: (messageSelectedId: string) => {
      set((state) => ({
        state: {
          ...state.state,
          messageSelectedId,
        },
      }));
    },

    setModalPreviewNotificationIsOpen: (modalPreviewNotificationIsOpen: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          modalPreviewNotificationIsOpen,
        },
      }));
    },

    setTemplateSelectedId: (templateSelectedId: string) => {
      set((state) => ({
        state: {
          ...state.state,
          templateSelectedId,
        },
      }));
    },

    setTypeList: (typeList: "grid" | "list") => {
      set((state) => ({
        state: {
          ...state.state,
          typeList,
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          messageSelectedId: undefined,
          modalPreviewNotificationIsOpen: false,
          templateSelectedId: undefined,
          typeList: "list",
        },
      }));
    },
  },
}));
