import { GLSignUp } from "@group-link-one/grouplink-components";
import { Helmet } from "react-helmet";

import { useSignUp } from "./useSignUp";

export const SignUp = () => {
  const { joinUser } = useSignUp();

  return (
    <>
      <GLSignUp onSubmit={joinUser} />
      <Helmet title="Sign Up" />
    </>
  );
};
