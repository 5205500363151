import { GL_COLORS } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { useGLPagination } from "../../../../contexts/GLPaginationContext/GLPaginationContext";
import { useListNotifications } from "../../../../Services/messageCenterService/useListNotifications/useListNotifications";
import { useCreateNotificationStore } from "../store/createNotification";
import { useSelectTemplateStore } from "../store/selectTemplate";

export const IPP_SELECT_TEMPLATES = 50

export function useSelectTemplate() {

  const { state: selectTemplateState, actions: selectTemplateActions } = useSelectTemplateStore();
  const { actions: createNotificationActions } = useCreateNotificationStore();

  const {
    state: { tabs, activeTabId, currentPage, search },
    actions: { getActiveTabById, getNextPageToken, setNextPageToken, setCurrentPage }
  } = useGLPagination()

  const { listNotifications } = useListNotifications()
  const activeTab = getActiveTabById(activeTabId || 1)

  const { data: templates, isLoading: templatesIsLoading } = useQuery({
    queryKey: ["get-message-center-modal-templates", currentPage, search], // terá mais um parâmetro de filtro quando entrar as outras tabs
    queryFn: async () => {

      const { nextPageToken, optionsToStoreNextPageToken } = getNextPageToken()

      const response = await listNotifications({
        ipp: IPP_SELECT_TEMPLATES,
        next_page_token: nextPageToken,
      })

      setNextPageToken({
        hasMore: response.has_more,
        nextPageToken: response.next_page_token,
        optionsToStoreNextPageToken,
      })

      return response.rows
    },
    staleTime: 1000 * 60,
  })

  function setButtonGridColor(type: "list" | "grid") {
    return type === selectTemplateState.typeList ? GL_COLORS.ACCENT_COLOR : GL_COLORS.FONT_COLOR_DARK_GREY;
  }

  function handleOnPaginate(page: number) {
    setCurrentPage(page)
  }

  useEffect(() => {
    createNotificationActions.setStepWasVisited("SELECT_TEMPLATE")
  }, [])

  return {
    tabs,
    selectTemplateState,
    selectTemplateActions,
    setButtonGridColor,
    templates,
    currentPage,
    templatesIsLoading,
    activeTab,
    handleOnPaginate
  };
}
