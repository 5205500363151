import { useMediaQuery } from "usehooks-ts";

import { ListNotificationsResponse } from "../../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
import { useNotificationsStore } from "../../../Notifications/store/notifications-store";
import { useSelectTemplateStore } from "../../store/selectTemplate";

export const useSelectTemplateCards = () => {
  const { state: selectTemplateState, actions: selectTemplateActions } = useSelectTemplateStore();
  const { actions: notificationsActions } = useNotificationsStore();

  const isMobile = useMediaQuery("(max-width: 1024px)");
  const isToUseCardMobile = isMobile || selectTemplateState.typeList === "grid";

  const categoryMap = {
    1: {
      title: "A-Class",
      description: "Informative",
    },
    2: {
      title: "B-Class",
      description: "Urgent",
    },
  }

  function openModalPreviewNotification() {
    selectTemplateActions.setModalPreviewNotificationIsOpen(true);
  }

  function onClickTemplateCard(template: ListNotificationsResponse) {
    if (template.title === selectTemplateState.templateSelectedId) {
      selectTemplateActions.setTemplateSelectedId("");
      notificationsActions.resetAll();
    } else {
      selectTemplateActions.setTemplateSelectedId(template.title);
      notificationsActions.setCategoryId(template.category_id);
      notificationsActions.setMessageId(template.message_id);

      notificationsActions.setPopupPreviewNotificationContent({
        title: template.title,
        message: template.body,
        categoryTitle: categoryMap[template.category_id as keyof typeof categoryMap].title,
        categoryDescription: categoryMap[template.category_id as keyof typeof categoryMap].description,
      });

    }
  }

  return {
    selectTemplateState,
    selectTemplateActions,
    openModalPreviewNotification,
    isToUseCardMobile,
    onClickTemplateCard,
    categoryMap
  }
}
