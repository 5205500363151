import { GLEventList } from "@group-link-one/grouplink-components";

import { EventListTableContent } from "./Content/TableContent";
import { useEventList } from "./useEventList";

export const EventList = () => {
  const { relativeFilterTimeOptions } = useEventList();

  return (
    <GLEventList
      relativeFilterTimeOptions={relativeFilterTimeOptions}
      tableContent={<EventListTableContent />}
      isDeviceIdSearchByEntireID={false}
      hasFilterByUserid
      hasFilterByRelativeTime
    />
  );
};
