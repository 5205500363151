import { GLButton, GLCardTemplate, GLTypography } from "@group-link-one/grouplink-components";
import { format } from "date-fns";

import { ListNotificationsResponse } from "../../../../../Services/messageCenterService/useListNotifications/useListNotifications.types";
import { useSelectTemplateCards } from "./useSelectTemplateCards";

interface SelectTemplateCardsProps {
  templates?: ListNotificationsResponse[];
}

export function SelectTemplateCards({ templates }: SelectTemplateCardsProps) {

  const { onClickTemplateCard, selectTemplateState, categoryMap } = useSelectTemplateCards();

  return (
    <>
      {templates && templates.map((template, index) => (
        <GLCardTemplate.Provider key={index}>
          <GLCardTemplate.Content
            style={{
              backgroundColor: selectTemplateState.templateSelectedId
                && selectTemplateState.templateSelectedId !== template.title ? "#F5F5F5" : "inherit",

              opacity: selectTemplateState.templateSelectedId
                && selectTemplateState.templateSelectedId !== template.title ? 0.7 : 1,

              pointerEvents: selectTemplateState.templateSelectedId
                && selectTemplateState.templateSelectedId !== template.title ? "none" : "auto",

              transition: "all 0.3s"
            }}
          >
            <GLCardTemplate.Header padding="18px 20px">

              <GLCardTemplate.Column width={45}>
                <GLTypography
                  text={template.title}
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={600}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column width={18} direction="column" gap={5}>
                <GLTypography
                  text="Last time sent"
                  as="span"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={3}
                  weight={600}
                />

                <GLTypography
                  text={format(new Date(template.updated_at), "MM/dd/yyyy")}
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column width={18} direction="column" gap={5}>
                <GLTypography
                  text="Category"
                  as="span"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={3}
                  weight={600}
                />

                <GLTypography
                  text={`
                    ${categoryMap[template.category_id as keyof typeof categoryMap].title}${" · "}
                    ${categoryMap[template.category_id as keyof typeof categoryMap].description}
                    `
                  }
                  as="p"
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Actions
                width={15}
                justify="flex-end"
                absolute
                style={{
                  right: "20px",
                  opacity: selectTemplateState.templateSelectedId === template.title ? 1 : "revert-layer",
                }}

              >
                <GLButton
                  text={selectTemplateState.templateSelectedId === template.title ? "Deslect" : "Select"}
                  variant="fill"
                  align="center"
                  weight={600}
                  onClick={() => onClickTemplateCard(template)}
                  style={{
                    width: "70%",
                  }}
                />
              </GLCardTemplate.Actions>

            </GLCardTemplate.Header>
          </GLCardTemplate.Content>
        </GLCardTemplate.Provider >
      ))
      }
    </>

  )
}
