import { GLHeaderNavListItemProps } from "@group-link-one/grouplink-components";
import { useMemo } from "react";

export enum MessageCenterRoutes {
  DEFAULT = "/message-center",
  SEND_AND_SCHEDULE = "/message-center/send-schedule",
  NOTIFICATIONS = "/message-center/notifications",
}

export function useMessageCenterLayout() {
  const headerNavList: GLHeaderNavListItemProps[] = useMemo(() => {
    return [
      {
        id: 1,
        title: "Notifications",
        path: MessageCenterRoutes.NOTIFICATIONS,
      },
    ];
  }, []);

  const headerNavItemActive = headerNavList.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return {
    headerNavList,
    headerNavItemActive,
  };
}
