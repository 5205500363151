import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const PopupPreviewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

   // apenas para mostrar modal ============================================
  align-items: flex-start;
  width: 70%;
  // ============================================

  .popup-content {
    display: grid;
    grid-template-columns: 1fr auto;

    // apenas para mostrar modal ============================================
    grid-template-columns: 1fr auto;
    width: 100%;
    // ============================================

    gap: 40px;

    .notification-content {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

  }

  .popup-footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    display: none;

  }

  @media (max-width: 900px) {
    width: 100%;

    .popup-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 40px;

      .notification-content {
        width: 100%;
      }

      .notification-platforms {
        align-self: center;
        justify-self: center;

        .notification-platform-preview {
          img {
            width: 100%;
          }
        }
      }
    }

    .popup-footer {
      width: 100%;
      justify-content: center;
    }
  }
`

export const NotificationPriority = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .priority-items {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  @media (max-width: 500px) {
    .priority-items {
      flex-direction: column;
      gap: 10px;
    }
  }


`

export const PrioriyItem = styled.div`
  padding: 12px 45px 12px 15px;
  border-radius: 10px;
  position: relative;

  border: 1px solid ${GL_COLORS.BORDER_COLOR};
  box-shadow: 0px 3px 0px ${GL_COLORS.SHADOW_COLOR};

  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  transition: all .4s;

  > button {
    position: absolute;
    top: 10px;
    right: 10px;

    background: none;
    border: none;

    svg {
      fill: ${GL_COLORS.FONT_COLOR};
    }
  }

  &.active {
    border: 1px solid ${GL_COLORS.ACCENT_COLOR};
    box-shadow: 0px 3px 0px ${GL_COLORS.ACCENT_COLOR};

    p {
      color: ${GL_COLORS.ACCENT_COLOR} !important;
    }

    > button {
      svg {
        fill: ${GL_COLORS.ACCENT_COLOR};
      }
    }
  }

  @media (max-width: 900px) {
    flex: 1;
  }
`

export const NotificationMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .notification-message-content {
    border: 1px solid ${GL_COLORS.BORDER_COLOR};
    padding: 20px;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 330px;

    // apenas para mostrar modal ============================================
    width: 100%;
    // ============================================

    > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  @media (max-width: 900px) {
    .notification-message-content {
      /* width: 70%; */
      width: 100%;
      max-width: 500px;
    }
  }
`

export const NotificationPreview = styled.div`
  background-color: transparent;
  perspective: 1000px;
  position: relative;

  .notification-preview-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform-origin: center;
  }

  .notification-preview-front, .notification-preview-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .notification-preview-front {
    > div {
      position: absolute;
      top: 190px;
      overflow-y: visible;

      text-align: left;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 115px;
      bottom: 0px;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${GL_COLORS.BACKGROUND_PRIMARY} 100%);
    }
  }

  .notification-preview-back {
    transform: rotateY(180deg);
    position: relative;

    > div {
      position: absolute;
      top: 190px;
      overflow-y: visible;

      text-align: left;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 115px;
      bottom: 0px;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${GL_COLORS.BACKGROUND_PRIMARY} 100%);
    }
  }

  &.android .notification-preview-inner {
    transform: rotateY(180deg);
  }

`
