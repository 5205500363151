export type ErrorType =
  | "invalid_email_or_pass"
  | "blocked_password"
  | undefined;

interface InfoAirErrorsProps {
  error: ErrorType;
}

export class InfoAirErrors {
  private error: ErrorType;

  constructor(props: InfoAirErrorsProps) {
    this.error = props.error;
  }

  private errorsMap() {
    return {
      invalid_email_or_pass: {
        title: "Login failed",
        message: "Invalid email or password",
      },

      blocked_password: {
        title: "Blocked account",
        message:
          "Your account has been blocked after several failed login attempts. Please use the 'Forgot Password?' option to log in again",
      },

      default: {
        title: "Error",
        message: "An error occurred",
      },
    };
  }

  public getError() {
    const error =
      this.error && this.errorsMap()[this.error]
        ? this.errorsMap()[this.error]
        : this.errorsMap().default;

    return error;
  }
}
