import React, {
  createContext,
  useContext,
  useRef,
} from "react";
import { createStore, StoreApi, useStore } from "zustand";

import { GLPaginationProviderProps, GLPaginationStore } from "./GLPaginationContext.types";

const GLPaginationContext = createContext({} as StoreApi<GLPaginationStore>);

export function GLPaginationProvider({ children, lastNextPageToken, tabs }: GLPaginationProviderProps) {
  const storeRef = useRef<StoreApi<GLPaginationStore>>();

  if (!storeRef.current) {
    storeRef.current = createStore<GLPaginationStore>((set) => ({
      state: {
        currentPage: 0,
        activeTabId: 1,
        tabs,
        search: "",
        lastNextPageToken: lastNextPageToken || {},
        nextPageTokenSearching: undefined,
      },
      actions: {
        setCurrentPage: (page) =>
          set((state: any) => ({
            state: {
              ...state.state,
              currentPage: page,
            },
          })),

        setActiveTab: (tabId: number) =>
          set((state: any) => ({
            state: {
              ...state.state,
              activeTabId: tabId,
            },
          })),

        setSearch: (search: any) =>
          set((state: any) => ({
            state: {
              ...state.state,
              search,
            },
          })),

        setNextPageTokenTab: ({ cacheKey, nextPageToken, page }) =>
          set((state: any) => ({
            state: {
              ...state.state,
              lastNextPageToken: {
                ...state.state.lastNextPageToken,
                [cacheKey]: {
                  token: nextPageToken,
                  page,
                },
              },
            },
          })),

        setNextPageTokenSearching: (nextPageToken: any) =>
          set((state: any) => ({
            state: {
              ...state.state,
              nextPageTokenSearching: nextPageToken,
            },
          })),

        setNextPageToken: ({ hasMore, nextPageToken, optionsToStoreNextPageToken }) => {
          const paginationState = storeRef.current?.getState().state;
          const paginationActions = storeRef.current?.getState().actions;

          if (!paginationState) return;
          if (!paginationActions) return;

          if (hasMore && nextPageToken) {
            if (paginationState.search.length > 0) {
              paginationActions.setNextPageTokenSearching(nextPageToken);
            } else {

              if (!optionsToStoreNextPageToken) return;

              const activeTab = paginationState.tabs.find(
                (tab) => tab.id === paginationState.activeTabId
              );

              const { page } = optionsToStoreNextPageToken;

              const nextPage = page ? page + 1 : 1;
              const cacheKey =
                String(activeTab?.cacheKey as keyof typeof paginationState.lastNextPageToken);

              paginationActions.setNextPageTokenTab({
                cacheKey,
                nextPageToken,
                page: nextPage,
              });
            }
          }
        },

        tabsActions: {
          setCount: (tab: any, count: any) =>
            set((state: any) => ({
              state: {
                ...state.state,
                tabs: state.state.tabs.map((t: any) =>
                  t.name === tab.name ? { ...t, count } : t
                ),
              },
            })),
          setOnClick: (tab: any, onClick: any) =>
            set((state: any) => ({
              state: {
                ...state.state,
                tabs: state.state.tabs.map((t: any) =>
                  t.name === tab.name ? { ...t, onClick } : t
                ),
              },
            })),
        },

        getActiveTabById: (tabId: number) => {
          return storeRef.current?.getState().state.tabs.find((tab: any) => tab.id === tabId);
        },

        getNextPageToken: () => {
          const currentState = storeRef.current?.getState().state;

          if (!currentState) return {
            nextPageToken: undefined,
            optionsToStoreNextPageToken: { page: undefined }
          };

          const isSearching = currentState.search.length > 0;
          let nextPageToken: string | undefined = undefined;
          let optionsToStoreNextPageToken: { page?: number } = {};

          if (isSearching) {
            const nextPageTokenSearching = currentState.nextPageTokenSearching;
            nextPageToken = nextPageTokenSearching;
          } else {
            const activeTab = currentState.tabs.find((tab) => tab.id === currentState.activeTabId);

            const nextPageTokenTab =
              currentState.lastNextPageToken[
              activeTab?.cacheKey as keyof typeof currentState.lastNextPageToken
              ];

            const { page, token } = nextPageTokenTab;
            const isToUseNextPageTokenStored = page === currentState.currentPage;

            if (isToUseNextPageTokenStored) {
              nextPageToken = token;
              optionsToStoreNextPageToken = { page };
            }

          }

          return {
            nextPageToken,
            optionsToStoreNextPageToken,
          }
        }

      },
    }));
  }

  return (
    <GLPaginationContext.Provider value={storeRef.current}>
      {children}
    </GLPaginationContext.Provider>
  );
}

export const useGLPagination = (selector?: (state: GLPaginationStore) => GLPaginationStore) => {
  const store = useContext(GLPaginationContext);

  if (!store) {
    throw new Error("Missing GLPaginationRoot");
  }

  return selector ? useStore(store, selector) : useStore(store);
};
