import styled, { css } from "styled-components";

const flexColumn = (gap: number) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
`;

export const SelectTemplateContainer = styled.div`
  ${flexColumn(30)}
  padding-bottom: 100px;

`;

export const ButtonChangeGrid = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
`

export const SelectTemplateFiltersBottom = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .select-template-refresh-list {
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    border: none;
    cursor: pointer;
    transition: all .4s;
    width: fit-content;

    &:hover {
      filter: brightness(.8);
    }
  }
`
