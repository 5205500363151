import { useAuth } from "@group-link-one/gl-auth";
// import { GL_SEND_MESSAGE, GL_SETTINGS } from "@group-link-one/grouplink-components";
// import { useMemo } from "react";
// import { useNavigate } from "react-router-dom";

// import { MainRoutes } from "../layouts/MainLayout/useMainLayout";
// import { MessageCenterLayout } from "../layouts/MessageCenterLayout/MessageCenterLayout";
// import { MessageCenterRoutes } from "../layouts/MessageCenterLayout/useMessageCenterLayout";
// import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
// import { SettingsRoutes } from "../layouts/SettingsLayout/useSettingsLayout";
// import { Apps } from "../Pages/Settings/Apps/apps";
// import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
// import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
// import { Overview } from "../Pages/Settings/Overview/overview";
// import { Users } from "../Pages/Settings/Users/organizationUsers";
// import { verifyIfPathIsActive } from "../utils/verifyIfPathIsActive";

// const allPages = [
//   "System",
//   "DeviceRegistering",
//   "Customer",
//   "Organization",
//   "OrganizationDevice",
//   "OrganizationUser",
//   "OrganizationVault",
//   "Dashboard",
//   "MessageCenter",
//   "Reports",
//   "InfoAir",
//   "ManufactureDeviceRegistering",
// ];

export const allPrivileges = {
  System: [["System.list-logs", "Listem system logs"]],
  InfoAir: [
    ["InfoAir.message-insert", "Create new messages on the organization"],
    ["InfoAir.message-edit", "Edit exiting messages on the organization"],
  ],
  DeviceRegistering: [
    ["DeviceRegistering.insert", "Insert DeviceRegistering"],
    ["DeviceRegistering.edit", "Edit DeviceRegistering"],
    ["DeviceRegistering.remove", "Remove existing DeviceRegistering"],
    ["DeviceRegistering.edit-firmware-version", "Edit Firmware Version"],
    ["DeviceRegistering.edit-device-model", "Edit device model"],
    [
      "DeviceRegistering.change-organization",
      "Change Device from Organization",
    ],
  ],
  ManufactureDeviceRegistering: [
    [
      "ManufactureDeviceRegistering.insert",
      "Insert ManufactureDeviceRegistering",
    ],
    [
      "ManufactureDeviceRegistering.list",
      "List ManufactureDeviceRegistering created by themselves",
    ],
    [
      "ManufactureDeviceRegistering.list-all-records",
      "List all records of ManufactureDeviceRegistering",
    ],
  ],
  Organization: [
    ["Organization.insert", "Insert new Organizations"],
    ["Organization.edit", "Edit Organizations"],
    ["Organization.generate-token", "View and generate SDK tokens"],
  ],

  OrganizationDevice: [
    [
      "OrganizationDevice.transfer-device",
      "Transfers Devices for allowed Organization",
    ],
    ["OrganizationDevice.activate", "Activate Devices on the Organization"],
    ["OrganizationDevice.list", "List Devices of the Organization"],
    ["OrganizationDevice.deactivate", "Deactivate previously activated device"],
    [
      "OrganizationDevice.deactivate.any-organization",
      "Deactivate previously activated device at any organization",
    ],
    [
      "OrganizationDevice.edit-offsets-at-any-time",
      "Edit offsets at any time on all devices of the Organization",
    ],
    [
      "OrganizationDevice.edit-offsets-limited",
      "Edit offsets, limited devices activated by themselves recently",
    ],
    [
      "OrganizationDevice.edit-device-info",
      "Edit name, location and remote-id",
    ],
    [
      "OrganizationDevice.delete",
      "Delete a device (while is in waiting for server side activation)",
    ],
    [
      "OrganizationDevice.device_name.insert",
      "Insert new device_name on Activation App",
    ],
    [
      "OrganizationDevice.device_name.list",
      "List device_name on Activation App",
    ],
    [
      "OrganizationDevice.device_name.remove",
      "Remove new device_name on Activation App",
    ],
    [
      "OrganizationDevice.organizational_unit.insert",
      "Insert new organizational_unit on Activation App",
    ],
    [
      "OrganizationDevice.organizational_unit.list",
      "List organizational_unit on Activation App",
    ],
    [
      "OrganizationDevice.organizational_unit.remove",
      "Remove new organizational_unit on Activation App",
    ],

    [
      "OrganizationDevice.macromicro_measurement.insert",
      "Insert Macro/Micro Measure on Activation App",
    ],
    [
      "OrganizationDevice.macromicro_measurement.list",
      "List Macro/Micro Measure Activation App",
    ],
    [
      "OrganizationDevice.macromicro_measurement.remove",
      "Remove Macro/Micro Measure on Activation App",
    ],

    [
      "OrganizationDevice.device_list_snapshot",
      "Download last values for all devices",
    ],
    [
      "OrganizationDevice.water_full_export",
      "Download complete events for water devices",
    ],
  ],

  Customer: [
    ["Customer.insert", "Insert any Customer"],
    ["Customer.edit", "Edit any Customer"],
    ["Customer.impersonation", "Impersonate any Customer"],
  ],

  OrganizationUser: [
    ["OrganizationUser.invite", "Invite new users"],
    ["OrganizationUser.edit", "Edit any user"],
    ["OrganizationUser.deactivate", "Deactivate any user"],
    ["OrganizationUser.activate", "Activate any user"],
  ],

  OrganizationVault: [
    [
      "OrganizationVault.admin",
      "List, create and remove any type secrets, such as Azure, AWS S3 or GCS credentials, stored in the organization vault, unless they are system managed",
    ],
    [
      "OrganizationVault.message-center-applications",
      "List, create and remove credentials for Android and iOS applications",
    ],
  ],

  Dashboard: [
    ["Dashboard.login", "Login to the web dashboard"],
    [
      "Dashboard.edit_strict_custom_list",
      "Edit strict custom lists of the Organization",
    ],
  ],

  Reports: [
    [
      "Reports.run-in-fg",
      "Re-execute reports in foreground (use for dev/testing only)",
    ],
  ],

  MessageCenter: [
    [
      "MessageCenter.manage-messages",
      "Can insert, edit, and remove campaigns and messages for the organization",
    ],
    [
      "MessageCenter.manage-applications",
      "Can insert, edit, and remove Android and iOS applications",
    ],
  ],

  Rules: [["Rules.list-all-profiles", "List all access profiles"]],
};

export function useAllowedPages() {
  useAuth();

  return [];
}
