import { GL_COLORS } from "@group-link-one/grouplink-components";
import { motion } from "framer-motion";
import styled from "styled-components";

export const CreateCampaignModalContentContainer = styled.div`
  margin-top: 50px;
  position: relative;
`;

export const CreateCampaignModalContentHeader = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  position: sticky;
  top: -100px;

  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  z-index: 999999;

  .steper-actions {
    display: grid;
    grid-template-columns: 48px 1fr;
    align-items: center;
    gap: 20px;
  }

  &.active {
    border-bottom: 1px solid ${GL_COLORS.BORDER_COLOR};
    height: 100px;
    width: calc(100% + 240px);
    margin-left: -120px;
    box-shadow: 0px 5px 15px #b2bdc533;
    padding-inline: 20px;

    &.mobile {
      top: -20px;
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }
`;

export const CreateCampaignModalContentBody = styled(motion.div).attrs({
  initial: { opacity: 0, x: "100vw" },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: "-100vw" },
  transition: {
    type: "tween",
    duration: 0.3,
  },
})`
  margin-top: 50px;
`;

export const SteperActionsMobile = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  padding: 20px 30px;
  border-top: 1px solid ${GL_COLORS.BORDER_COLOR};
  box-shadow: 0px -5px 15px #b2bdc533;

  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};

  z-index: 999;

  @media (max-width: 440px) {
    justify-content: center;
  }
`;
