import "./Services/remoteConfig";

import {
  GLMenuMobileProvider,
  GLModalLogoutProvider,
  GLToastProvider,
  I18n,
} from "@group-link-one/grouplink-components";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import FlagsProvider from "./Context/FlagsProvider";
import { useCallbackToModalLogout } from "./hooks/useCallbackToModalLogout";
import { Routes } from "./Routes/index.routes";

function App() {
  const { callbackFnToModalLogout } = useCallbackToModalLogout();

  return (
    <>
      <FlagsProvider>
        <GLModalLogoutProvider
          callbackFnToModalLogout={callbackFnToModalLogout}
        >
          <GLMenuMobileProvider>
            <GLToastProvider>
              <I18n>
                <Routes />
              </I18n>
            </GLToastProvider>
          </GLMenuMobileProvider>
        </GLModalLogoutProvider>
      </FlagsProvider>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
