import {
  GL_CHEVRON_LEFT,
  GLButton,
  GLButtonIcon,
  GLSteper,
  StepProps,
} from "@group-link-one/grouplink-components";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

import { PopupPreviewContent } from "../../Notifications/PopupPreview/PopupPreviewContent/PopupPreviewContent";
// import { Review } from "../Review/Review";
// import { Schedule } from "../Schedule/Schedule";
import { Segmentation } from "../Segmentation/segmentation";
import { SelectTemplate } from "../SelectTemplate/SelectTemplate";
import { useCreateNotificationStore } from "../store/createNotification";
import {
  CreateCampaignModalContentBody,
  CreateCampaignModalContentContainer,
  CreateCampaignModalContentHeader,
  SteperActionsMobile,
} from "./modalContentStyle";
import { useModalContent } from "./useModalContent";

const contentsPerStep = {
  "select-template": <SelectTemplate />,
  segmentation: <Segmentation />,
  "review-template": <PopupPreviewContent />
};

export function ModalCreateNotificationContent() {
  const refModalContent = useRef<HTMLDivElement>(null);
  const { state, actions } = useCreateNotificationStore();

  const { formatTextToNextStep, handleOnClickToAdvanceStep, isSendingNotification } =
    useModalContent();

  const currentStep = state.steps.find(
    (step) => step.id === state.stepActive
  ) as StepProps;

  const mediaIsMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <CreateCampaignModalContentContainer ref={refModalContent}>
      <CreateCampaignModalContentHeader
        className={`
          modal-content-header
          ${state.steperIsOnTop && "active"}
          ${mediaIsMobile ? "mobile" : ""}
        `}
        layoutScroll
      >
        <GLSteper
          steps={state.steps}
          stepActive={currentStep}
          handleStepActiveOnClickInStep={(step) => {
            if (
              (step.id <= state.stepActive || step.wasVisited) &&
              step.id !== state.stepActive
            ) {
              actions.setStepActive(step.id);
            }
          }}
          isMobile={mediaIsMobile}
        />

        {!mediaIsMobile && (
          <motion.div layout className="steper-actions">
            <GLButtonIcon
              icon={<GL_CHEVRON_LEFT />}
              variant="outline"
              disabled={currentStep.id === 0}
              onClick={() => {
                if (currentStep.id > 0) {
                  actions.setStepActive(currentStep.id - 1);
                }
              }}
            />

            <GLButton
              text={formatTextToNextStep(currentStep)}
              variant="fill"
              align="center"
              onClick={() => handleOnClickToAdvanceStep(currentStep)}
              isLoading={isSendingNotification}
              size="primary"
              style={{
                padding: "15px 0px",
                minWidth: "250px"
              }}
            />
          </motion.div>
        )}
      </CreateCampaignModalContentHeader>

      <AnimatePresence mode="wait">
        <CreateCampaignModalContentBody key={currentStep?.key}>
          {currentStep
            ? contentsPerStep[currentStep?.key as keyof typeof contentsPerStep]
            : null}
        </CreateCampaignModalContentBody>
      </AnimatePresence>

      {mediaIsMobile && (
        <SteperActionsMobile layout>
          <GLButtonIcon
            icon={<GL_CHEVRON_LEFT />}
            variant="outline"
            disabled={currentStep.id === 0}
            onClick={() => {
              if (currentStep.id > 0) {
                actions.setStepActive(currentStep.id - 1);
              }
            }}
          />

          <GLButton
            text={formatTextToNextStep(currentStep)}
            variant="fill"
            align="center"
            onClick={() => handleOnClickToAdvanceStep(currentStep)}
            style={{
              padding: "15px 40px",
              height: "fit-content",
              width: "fit-content",
            }}
            isLoading={isSendingNotification}
            size="primary"
          />
        </SteperActionsMobile>
      )}
    </CreateCampaignModalContentContainer>
  );
}
