import { GLMainTemplate } from "@group-link-one/grouplink-components";
import { Outlet } from "react-router-dom";

import GLLogoDark from "../../images/Common/logo-main-dark.svg";
import GLLogoLight from "../../images/Common/logo-main-light.svg";
import { useMainLayout } from "./useMainLayout";

export function MainLayout() {
  const { navListTop, navListBottom } = useMainLayout();
  return (
    <GLMainTemplate
      asideOptions={{
        navListTop,
        navListBottom,
        logoOptions: {
          src: {
            logoLight: GLLogoLight,
            logoDark: GLLogoDark
          },
          alt: "Logo GL Info Air",
          clipPath: 35,
          position: 60,
        },
      }}
    >
      <Outlet />
    </GLMainTemplate>
  );
}
