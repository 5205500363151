import useAxiosHTTPRequest from "../../useAxiosHTTPRequest"
import { GetCountriesParams, GetCountriesResponse, GetDivisionsParams, GetDivisionsResponse, GetDivisionsSummaryParams, GetDivisionsSummaryResponse, GetStatesParams, GetStatesResponse } from "./useListGeoAdmnistrativeAreas.types"

export const useListGeoAdmnistrativeAreas = () => {
  const { httpPrivate } = useAxiosHTTPRequest()

  async function getCountries({ code, name }: GetCountriesParams): Promise<GetCountriesResponse["rows"]> {
    const response = await httpPrivate.get<GetCountriesResponse>("/geo-administrative-area/countries", {
      params: {
        code,
        name
      }
    })

    return response.data.rows
  }

  async function getStates({ country_code, state_code, state_name }: GetStatesParams): Promise<GetStatesResponse["rows"]> {
    const response = await httpPrivate.get<GetStatesResponse>("/geo-administrative-area/states", {
      params: {
        country_code,
        state_code,
        state_name
      }
    })

    return response.data.rows
  }

  async function getDivisions({
    depth,
    division_name,
    division_type,
    parent_id
  }: GetDivisionsParams): Promise<GetDivisionsResponse["rows"]> {
    const response = await httpPrivate.get<GetDivisionsResponse>("/geo-administrative-area/divisions", {
      params: {
        depth,
        division_name,
        division_type,
        parent_id
      }
    })

    return response.data.rows
  }

  async function getDivisionsSummary({ ...params }: GetDivisionsSummaryParams): Promise<GetDivisionsSummaryResponse["rows"]> {
    const response = await httpPrivate.get<GetDivisionsSummaryResponse>("/geo-administrative-area/divisions/summary", {
      params: {
        ...params
      }
    })

    return response.data.rows
  }

  return {
    getCountries,
    getStates,
    getDivisions,
    getDivisionsSummary
  }
}
