import { useEffect, useState } from "react";

import AndroidPhoneImage from "../../../../../images/MessageCenter/phones/android.png";
import IphoneDarkImage from "../../../../../images/MessageCenter/phones/iphone-dark.png";
import IphoneLightImage from "../../../../../images/MessageCenter/phones/iphone-light.png";
import { useCreateNotificationStore } from "../../../ModalCreateNotification/store/createNotification";

export function usePopupPreviewNotification() {

  const [iphonePhoneImage, setIphonePhoneImage] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? IphoneDarkImage
      : IphoneLightImage,
  );
  const { actions: createNotificationActions } = useCreateNotificationStore();

  const androidPhoneImage = AndroidPhoneImage;

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    function updatePlaformImageWhenDarkMode() {
      setIphonePhoneImage(
        darkModeMediaQuery.matches ? IphoneDarkImage : IphoneLightImage,
      );
    }

    darkModeMediaQuery.addEventListener("change", updatePlaformImageWhenDarkMode);

    return () => {
      darkModeMediaQuery.removeEventListener("change", updatePlaformImageWhenDarkMode);
    }
  }, [])

  useEffect(() => {
    createNotificationActions.setStepWasVisited("REVIEW")
  }, [])

  return {
    iphonePhoneImage,
    androidPhoneImage,
  }
}
