import { useAuth } from "@group-link-one/gl-auth";
import { GLPageNotFound } from "@group-link-one/grouplink-components";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-dark.svg";
import GLLogo from "../images/Common/logo-light.svg";
import { OnboardLayout } from "../layouts/OnboardLayout/OnboardLayout";
import { NewPassword } from "../Pages/Onboard/CreateNewPassword/newPassword";
import { Forgot } from "../Pages/Onboard/Forgot/forgot";
import { Login } from "../Pages/Onboard/Login/login";
import { SignUp } from "../Pages/Onboard/SignUp/signUp";
import { VerifyRoute } from "./VerifyRoute";

export const AuthRoutes = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();

  return (
    <Routes>
      <Route
        element={<VerifyRoute needAuth={false} element={<OnboardLayout />} />}
      >
        <Route
          path="forgot-password"
          element={<VerifyRoute needAuth={false} element={<Forgot />} />}
        />

        <Route
          path="/"
          element={<VerifyRoute needAuth={false} element={<Login />} />}
        />

        <Route
          path="/login"
          element={
            <VerifyRoute needAuth={false} element={<Navigate to="/" />} />
          }
        />
      </Route>

      {!accessToken && (
        <>
          <Route element={<OnboardLayout />}>
            <Route path="/join-organization" element={<SignUp />} />
            <Route path="/new-password" element={<NewPassword />} />
          </Route>

          <Route
            path="*"
            element={
              <GLPageNotFound
                logoOptions={{
                  src: {
                    logoLight: GLLogo,
                    logoDark: GLLogoDark,
                  },
                  backgroundImage: RightLogo404,
                  alt: "GroupLink logo",
                  logoRight: Logo404,
                }}
                onClickButton={() => navigate("/")}
                title="Oops!"
                subtitle="Page not found"
                description="It looks like you were detached from our IoT world..."
              />
            }
          />
        </>
      )}
    </Routes>
  );
};
