
import { MunicipalityPerStateFormatted, StatesMap } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { useListGeoAdmnistrativeAreas } from "../../../../../../Services/messageCenterService/useListGeoAdmnistrativeAreas/useListGeoAdmnistrativeAreas";
import { useSegmentationStore } from "../../../store/segmentation";

export const useListMunicipalities = () => {
  const [municipalitiesPerState] =
    useState<MunicipalityPerStateFormatted | undefined>(undefined);

  const { getStates, getDivisionsSummary, getDivisions } = useListGeoAdmnistrativeAreas();

  const {
    state: segmentationState,
    actions: segmentationActions
  } = useSegmentationStore();

  function onOpenAccordionState(stateCode: string, stateId: number) {

    if (segmentationState.stateSelected === stateCode) {
      segmentationActions.setStateSelected(undefined);
      segmentationActions.setStateSelectedId(undefined);
      segmentationActions.setMunicipalitySelectedId(undefined);
      return;
    }

    segmentationActions.setStateSelected(stateCode as StatesMap);
    segmentationActions.setStateSelectedId(stateId);
  }

  function onClickInMunicipality(e: React.MouseEvent<HTMLDivElement, MouseEvent>, municipality: string, index: number) {
    e.stopPropagation();
    e.preventDefault();

    segmentationActions.setWasClickedByMap(false);
    segmentationActions.setMunicipalitySelected(municipality);
    accordionListScrollTo(index);
  }

  function accordionListScrollTo(index: number) {
    const listContainer = document.querySelector(".accordion-list-municipalities");
    const offsetTop = index * 45;

    listContainer?.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
  }

  function getAllMuciipalitiesByStateId(stateId: number) {
    return municipalitiesCountPerState?.find((state) => state.parent_id === stateId)?.count || 0;
  }

  const { data: statesBRA, isLoading: statesBRAIsLoading } = useQuery({
    queryKey: ["all-states-BRA"],
    queryFn: async () => {
      const response = await getStates({
        country_code: "BRA"
      });

      return response
    },
    staleTime: 1000 * 60 * 60 * 24,
  })

  const { data: municipalitiesCountPerState } = useQuery({
    queryKey: ["municipalities-count-per-state-BRA"],
    queryFn: async () => {
      const response = await getDivisionsSummary({
        division_type: ["MUNICIPALITY"],
        group_by_parent_id: true,
      })

      return response
    },
    staleTime: 1000 * 60 * 60 * 24,
  })

  const {
    data: municipalitiesInStateActive,
    isLoading: municipalitiesInStateActiveIsLoading
  } = useQuery({
    queryKey: ["municipalities-per-state-BRA", segmentationState.stateSelectedId ? segmentationState.stateSelectedId : "none"],
    queryFn: async () => {
      if (!segmentationState.stateSelectedId) return [];

      const response = await getDivisions({
        division_type: ["MUNICIPALITY"],
        parent_id: [segmentationState.stateSelectedId],
      })

      return response
    },
    staleTime: 1000 * 60 * 60 * 24,
  })

  useEffect(() => {
    // AÇÃO MUNICIPIO SELECIONADO
    if (segmentationState.municipalitiesSelected?.length === 0) return;

    const indexMunicipalitySelected =
      municipalitiesInStateActive?.findIndex((municipality) => segmentationState.municipalitiesSelected?.includes(municipality.division_name)) || 0;

    accordionListScrollTo(indexMunicipalitySelected);

  }, [segmentationState.municipalitiesSelected])

  useEffect(() => {
    const firstMunicipalitySelected = segmentationState.municipalitiesSelected?.[0];
    if (!firstMunicipalitySelected) return;

    const indexOfMunicipalitySelected = municipalitiesInStateActive?.findIndex(
      (municipality) => municipality.division_name === firstMunicipalitySelected
    )

    if (!indexOfMunicipalitySelected && indexOfMunicipalitySelected !== -1) return;

    accordionListScrollTo(indexOfMunicipalitySelected);

  }, [municipalitiesInStateActive])

  useEffect(() => {
    segmentationActions.setMunicipalitiesIsLoading(municipalitiesInStateActiveIsLoading);
  }, [municipalitiesInStateActiveIsLoading])

  useEffect(() => {
    segmentationActions.setStatesIsLoading(statesBRAIsLoading);
  }, [statesBRAIsLoading])

  return {
    municipalitiesPerState,
    segmentationState,
    segmentationActions,
    onOpenAccordionState,
    onClickInMunicipality,
    statesBRA,
    statesBRAIsLoading,
    municipalitiesCountPerState,
    getAllMuciipalitiesByStateId,
    municipalitiesInStateActive,
    municipalitiesInStateActiveIsLoading,
  };
}
