import {
  GL_SEND_MESSAGE,
  GL_SETTINGS,
  GL_SHARE,
  GLAsideItemProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useNavigate } from "react-router-dom";

import { useFlags } from "../../Context/FlagsProvider";
import { verifyIfPathIsActive } from "../../utils/verifyIfPathIsActive";
import { MessageCenterRoutes } from "../MessageCenterLayout/useMessageCenterLayout";

export enum MainRoutes {
  DEVICES = "/devices",
  MESSAGE_CENTER = "/message-center",
  SETTINGS = "/settings",
  EVENT_LIST = "/network/event-list",
}

export function useMainLayout() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { hasPrivileges } = useFlags();

  const navListTop: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SEND_MESSAGE,
      text: "Message Center",
      active: verifyIfPathIsActive(MainRoutes.MESSAGE_CENTER, false),
      dropdownItems: [
        {
          id: 1,
          text: "Notifications",
          onClick: () => navigate(MessageCenterRoutes.NOTIFICATIONS),
        },
      ],
      onClick: () => {
        // navigate(MainRoutes.MESSAGE_CENTER);
      },
    },
    {
      id: 2,
      Icon: GL_SHARE,
      text: t("network.subtitle"),
      active: verifyIfPathIsActive(MainRoutes.EVENT_LIST, false),
      isToAppear: hasPrivileges(["show_network_event_page"]),
      dropdownItems: [
        {
          id: 1,
          isToAppear: true,
          text: t("eventList.subtitle"),
          onClick: () => navigate(MainRoutes.EVENT_LIST),
        },
      ],
      onClick: () => { },
    },
  ];

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 4,
      Icon: GL_SETTINGS,
      text: t("pages.settings.title"),
      active: verifyIfPathIsActive(MainRoutes.SETTINGS, false),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  return { navListTop, navListBottom };
}
