import { GLCreateNewPassword } from "@group-link-one/grouplink-components";
import { Helmet } from "react-helmet";

import { useNewPassword } from "./useNewPassword";

export const NewPassword = () => {
  const { rewrite } = useNewPassword();

  return (
    <>
      <GLCreateNewPassword onSubmit={rewrite} />
      <Helmet title="New Password" />
    </>
  );
};
