import { OnSelectMunicipalityProps, OnSelectStateProps } from "@group-link-one/grouplink-components"
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { GetDivisionsResponse, GetStatesResponse } from "../../../../Services/messageCenterService/useListGeoAdmnistrativeAreas/useListGeoAdmnistrativeAreas.types";
import { useCreateNotificationStore } from "../store/createNotification";
import { useSegmentationStore } from "../store/segmentation";

export function useSegmentation() {

  const { actions: createNotificationActions } = useCreateNotificationStore();
  const { actions: segmentationActions, state: segmentationState } = useSegmentationStore();
  const queryClient = useQueryClient()

  function getStateSelectedId(stateCode: string) {
    const states: GetStatesResponse["rows"] | undefined = queryClient.getQueryData(["all-states-BRA"]);
    const stateSelectedId = states?.find((state) => state.code === stateCode)?.division.id;

    return stateSelectedId;
  }

  function getMunicipalitySelectedId(municipalityName: string) {
    const municipalities: GetDivisionsResponse["rows"] | undefined =
      queryClient.getQueryData(["municipalities-per-state-BRA", segmentationState.stateSelectedId]);

    const municipalitySelectedId = municipalities?.find((municipality) => municipality.division_name === municipalityName)?.id;


    return municipalitySelectedId;
  }

  function onSelectState({ stateId }: OnSelectStateProps) {
    segmentationActions.setStateSelected(stateId);
    segmentationActions.setStateClicked(stateId);

    const stateIdSelected = getStateSelectedId(stateId);
    segmentationActions.setStateSelectedId(stateIdSelected);
  }

  function onSelectedMunicipality({ municipality, clickedByMap }: OnSelectMunicipalityProps) {

    if (clickedByMap) {
      segmentationActions.setWasClickedByMap(true);
      segmentationActions.setMunicipalitySelected(municipality);
    }

    const municipalityIdSelected = getMunicipalitySelectedId(municipality);
    segmentationActions.setMunicipalitySelectedId(municipalityIdSelected);

  }

  function onReset() {
    segmentationActions.setStateSelected(undefined);
    segmentationActions.setStateSelectedId(undefined);
    segmentationActions.setStateClicked(undefined);
    segmentationActions.setMunicipalitySelectedId(undefined);
  }

  useEffect(() => {
    segmentationActions.resetAll();
    createNotificationActions.setStepWasVisited("SEGMENTATION");
  }, [])

  return {
    onReset,
    onSelectState,
    onSelectedMunicipality,
  };
}
