import { create } from "zustand";

interface SetPopupPreviewNotificationContent {
  title: string;
  message: string;
  categoryTitle: string;
  categoryDescription: string;
}

interface NotificationsStoreState {
  popupPreviewNotificationIsOpen: boolean
  popupPreviewNotificationTitle: string | undefined
  popupPreviewNotificationMessage: string | undefined
  categoryId: number | undefined
  categoryTitle: string | undefined
  categoryDescription: string | undefined
  messageId: number | undefined
}

interface NotificationsStoreActions {
  setPopupPreviewNotificationIsOpen: (isOpen: boolean) => void
  setPopupPreviewNotificationContent: ({
    categoryDescription,
    categoryTitle,
    message,
    title,
  }: SetPopupPreviewNotificationContent) => void
  setCategoryId: (categoryId: number) => void

  setMessageId: (messageId: number) => void

  resetAll: () => void
}

interface NotificationsStore {
  state: NotificationsStoreState;
  actions: NotificationsStoreActions;
}

export const useNotificationsStore = create<NotificationsStore>((set) => ({
  state: {
    popupPreviewNotificationIsOpen: false,
    popupPreviewNotificationTitle: undefined,
    popupPreviewNotificationMessage: undefined,
    categoryTitle: undefined,
    categoryDescription: undefined,
    categoryId: undefined,
    messageId: undefined,
  },
  actions: {
    setPopupPreviewNotificationIsOpen: (isOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          popupPreviewNotificationIsOpen: isOpen,
        }
      })),

    setPopupPreviewNotificationContent: ({ categoryDescription, categoryTitle, message, title }) =>
      set((state) => ({
        state: {
          ...state.state,
          popupPreviewNotificationTitle: title,
          popupPreviewNotificationMessage: message,
          categoryTitle,
          categoryDescription,
        }
      })),

    setCategoryId: (categoryId) => set((state) => ({
      state: {
        ...state.state,
        categoryId,
      }
    })),

    setMessageId: (messageId) => set((state) => ({
      state: {
        ...state.state,
        messageId: messageId,
      }
    })),

    resetAll: () => set((state) => ({
      state: {
        ...state.state,
        popupPreviewNotificationIsOpen: false,
        popupPreviewNotificationTitle: undefined,
        popupPreviewNotificationMessage: undefined,
        categoryTitle: undefined,
        categoryDescription: undefined,
        categoryId: undefined,
      }
    })),
  },
}));
