import { GLSettingsOverview } from "@group-link-one/grouplink-components";

import { useFlags } from "../../../Context/FlagsProvider";
import { PageAnimated } from "../../../utils/pageAnimated";
import { useOverview } from "./useOverview";

export const SettingsOverview = () => {
  const { hasPrivileges } = useFlags();
  const { count, navigate, user, isLoading } = useOverview();

  return (
    <PageAnimated>
      <GLSettingsOverview
        hasPrivileges={hasPrivileges}
        isLoading={isLoading}
        user={user!}
        count={String(count)}
        editProfileButtonAction={() => navigate("/settings/my-account")}
        navigateToApps={() => navigate("/developers/apps")}
        navigateToLoginSecurity={() => navigate("/settings/login-security")}
      />
    </PageAnimated>
  );
};
