import { GL_COLORS, GLButton } from "@group-link-one/grouplink-components";

import { useCreateNotificationStore } from "../../ModalCreateNotification/store/createNotification";

export function NotificationsRightContent() {
  const { actions } = useCreateNotificationStore();

  return (
    <GLButton
      variant="fill"
      align="center"
      weight={600}
      text="New notification"
      onClick={() => actions.setModalCreateNotificationIsOpen(true)}
      style={{
        width: "fit-content",
        padding: "15px 30px",
        color: GL_COLORS.FONT_COLOR_VARIANT,
      }}
    />
  );
}
