import { IForgotData, IRewritePassword } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useForgotPasswordService = () => {
  const { http } = useAxiosHTTPRequest();

  const rewritePassword = async (data: IRewritePassword) => {
    return http.post("/rewrite-password", data);
  };

  const sendCode = () => { };

  const sendResetPasswordLink = async (data: IForgotData) => {
    const response = http.post("/reset-password", data, {
      method: "POST",
    });

    return response;
  };

  return {
    sendCode,
    sendResetPasswordLink,
    rewritePassword,
  };
};
