import axios from "axios";

import { PaginateResponseProps } from "../../../contexts/GLPaginationContext/GLPaginationContext.types";
import { env } from "../../../env";
import useAxiosHTTPRequest from "../../useAxiosHTTPRequest";
import { CreateNotificationParams, CreateNotificationResponse, GetNotificationsDeliveredResponse, ListNotificationsParams, ListNotificationsResponse, SendNotificationParams, SendNotificationResponse } from "./useListNotifications.types";

export function useListNotifications() {
  const { httpPrivate } = useAxiosHTTPRequest();

  async function listNotifications({
    ipp,
    next_page_token
  }: ListNotificationsParams): Promise<PaginateResponseProps<ListNotificationsResponse[]>> {
    const response = await httpPrivate.get("/info-air/message", {
      params: {
        ipp,
        next_page_token,
      },
    });
    return response.data;
  }

  async function sendNotification({
    division_id,
    message_id
  }: SendNotificationParams): Promise<SendNotificationResponse> {

    const response = await axios.get(env.REACT_APP_API_SEND_NOTIFICATION_URL, {
      params: {
        division_id,
        message_id,
        authToken: env.REACT_APP_API_SEND_NOTIFICATION_AUTH_TOKEN
      }
    })

    return response.data;
  }

  async function createNotification({ key, value }: CreateNotificationParams): Promise<CreateNotificationResponse> {
    const response = await httpPrivate.post("/org-kv", {
      key,
      value
    });
    return response.data;
  }

  // async function resetNotifications(): Promise<CreateNotificationResponse> {
  // const response = await httpPrivate.post("/org-kv", {
  //   key: "",
  //   value: { "content": null }
  // });
  // return response.data;
  // }

  async function getNotificationsDelivered(): Promise<GetNotificationsDeliveredResponse> {
    const response = await httpPrivate.get("/org-kv");
    return response.data;
  }

  return {
    listNotifications,
    sendNotification,
    createNotification,
    getNotificationsDelivered,
    // resetNotifications
  }

}
