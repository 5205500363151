import { GLMainContent } from "@group-link-one/grouplink-components";

import { TabProps } from "../../../@types/TabProps";
import { GLPaginationProvider } from "../../../contexts/GLPaginationContext/GLPaginationContext";
import { ModalCreateNotification } from "../ModalCreateNotification/ModalCreateNotification";
import { NotificationsContent } from "./Content/NotificationsContent";
import { NotificationsRightContent } from "./RightContent/NotificationsRightContent";

export function Notifications() {
  const tabs: TabProps[] = [
    {
      id: 1,
      cacheKey: "message-center-notifications-delivered",
      name: "Delivered",
      count: 0,
      active: true,
      onClick: () => { },
    }
  ]

  const lastNextPageToken = {
    "message-center-notifications-delivered": {
      token: undefined,
      page: undefined,
    }
  }

  return (
    <GLPaginationProvider tabs={tabs} lastNextPageToken={lastNextPageToken}>
      <GLMainContent
        content={<NotificationsContent />}
        rightContent={<NotificationsRightContent />}
        subtitle="message center"
        title="Notifications"
        hasList={true}
      />

      <ModalCreateNotification />
    </GLPaginationProvider>
  );
}
