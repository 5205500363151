import { GL_COLORS } from "@group-link-one/grouplink-components";
import { motion } from "framer-motion";
import styled from "styled-components";


export const ListMunicipalitiesContainer = styled(motion.div)`
  .accordion-list-municipalities {
    transition: all .4s;

    > div {
      max-width: 100% !important;

      > div {
        transition: all .4s;
        /* padding-left: 60px; */

        &:hover,
        &.active {
          background-color: ${GL_COLORS.HOVER_COLOR_PRIMARY};
          cursor: pointer;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${GL_COLORS.ACCENT_COLOR};
      border-radius: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${GL_COLORS.DISABLED_BACKGROUND_COLOR};
      border-radius: 12px;
    }
  }
`
