import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const NotificationsCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const NotificationStatusContainer = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  padding: 8px 10px;

  position: relative;

  &::before {
    content: "";
    position: absolute;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-color: ${GL_COLORS.SUCCESS};
    opacity: 0.1;
  }
`
