import { GLForgotPassword } from "@group-link-one/grouplink-components";
import { Helmet } from "react-helmet";

import { useForgotPassword } from "./useForgot";

export const Forgot = () => {
  const { sendForm, redirectToLogin, disabled } = useForgotPassword();

  return (
    <>
      <GLForgotPassword
        onRedirectToLogin={redirectToLogin}
        onSubmit={sendForm}
        isLoading={disabled}
      />
      <Helmet title="Forgot password" />
    </>
  );
};
