import { useAuth } from "@group-link-one/gl-auth";
import { useGLModalLogout } from "@group-link-one/grouplink-components";
import axios from "axios";
import { useEffect } from "react";

import { env } from "../env";

const useAxiosHTTPRequest = () => {
  const { accessToken } = useAuth();
  const { handleOpenModalLogout } = useGLModalLogout();

  const api = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const apiPrivate = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
      "User-Agent": window.navigator.userAgent,
      "x-timestamp": new Date().getTime(),
    }
  });

  useEffect(() => {
    apiPrivate.interceptors.request.use((config) => {
      config.headers.Authorization = accessToken;
      return config;
    });

    apiPrivate.interceptors.response.use(
      async (response) => {
        // await new Promise((resolve) => {
        // setTimeout(() => {
        // resolve(true);
        // }, 2000);
        // });

        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          handleOpenModalLogout();
        }
        return Promise.reject(error);
      }
    );
  }, [accessToken, apiPrivate, handleOpenModalLogout]);

  return {
    http: api,
    httpPrivate: apiPrivate,
  };
};

export default useAxiosHTTPRequest;
