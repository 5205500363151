import { StatesMap } from "@group-link-one/grouplink-components"
import { create } from "zustand";

interface SegmentationStoreState {
  stateSelected: StatesMap | undefined;
  stateClicked: StatesMap | undefined;
  stateSelectedId: number | undefined;
  statesIsLoading: boolean;
  wasClickedByMap: boolean;
  municipalityIndexSelected: number | undefined;
  municipalitiesSelected: string[] | undefined;
  municipalitySelectedId: number | undefined;
  municipalitiesIsLoading: boolean;
}

interface SegmentationStoreActions {
  setStateSelected: (state: StatesMap | undefined) => void;
  setStateSelectedId: (stateId: number | undefined) => void;
  setStateClicked: (state: StatesMap | undefined) => void;
  setStatesIsLoading: (isLoading: boolean) => void;
  setWasClickedByMap: (wasClickedByMap: boolean) => void;
  setMunicipalityIndexSelected: (municipalityIndex: number) => void;
  setMunicipalitySelected: (municipality: string) => void;
  setMunicipalitySelectedId: (municipalityId: number | undefined) => void;
  setMunicipalitiesIsLoading: (isLoading: boolean) => void;
  resetAll: () => void
}

interface UsersStore {
  state: SegmentationStoreState;
  actions: SegmentationStoreActions;
}

export const useSegmentationStore = create<UsersStore>((set) => ({
  state: {
    stateSelected: undefined,
    stateSelectedId: undefined,
    statesIsLoading: false,
    wasClickedByMap: false,
    stateClicked: undefined,
    municipalityIndexSelected: undefined,
    municipalitiesSelected: undefined,
    municipalitySelectedId: undefined,

    municipalitiesIsLoading: false,
  },
  actions: {
    setStateSelected: (stateId) => set((state) => ({
      state: {
        ...state.state,
        stateSelected: stateId,
      },
    })),

    setStateSelectedId: (stateId) => set((state) => ({
      state: {
        ...state.state,
        stateSelectedId: stateId,
      },
    })),

    setStateClicked: (stateId) => set((state) => ({
      state: {
        ...state.state,
        stateClicked: stateId,
      },
    })),

    setStatesIsLoading: (isLoading) => set((state) => ({
      state: {
        ...state.state,
        statesIsLoading: isLoading,
      },
    })),

    setWasClickedByMap: (wasClickedByMap) => set((state) => ({
      state: {
        ...state.state,
        wasClickedByMap: wasClickedByMap,
      },
    })),

    setMunicipalityIndexSelected: (municipalityIndex) => set((state) => ({
      state: {
        ...state.state,
        municipalityIndexSelected: municipalityIndex,
      },
    })),

    setMunicipalitySelected: (municipality) => set((state) => ({
      state: {
        ...state.state,
        municipalitiesSelected: [municipality]
      },
    })),

    setMunicipalitySelectedId: (municipalityId) => set((state) => ({
      state: {
        ...state.state,
        municipalitySelectedId: municipalityId,
      },
    })),

    setMunicipalitiesIsLoading: (isLoading) => set((state) => ({
      state: {
        ...state.state,
        municipalitiesIsLoading: isLoading,
      },
    })),

    resetAll: () => set((state) => ({
      state: {
        ...state.state,
        stateSelected: undefined,
        stateSelectedId: undefined,
        stateClicked: undefined,
        municipalityIndexSelected: undefined,
        municipalitiesSelected: undefined,
        municipalitySelectedId: undefined,
      },
    })),
  },
}));
