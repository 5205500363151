import { GLBrazilMap, GLTypography, GLZoomPanPinch } from "@group-link-one/grouplink-components";
import { memo } from "react";

// import { AnimatePresence, motion } from "framer-motion";
// import { useMediaQuery } from "usehooks-ts";
import { useSegmentationStore } from "../store/segmentation";
import { ListMunicipalities } from "./components/ListMunicipalities/ListMunicipalities";
import { SegmentationContainer } from "./segmentationStyle";
import { useSegmentation } from "./useSegmentation";

function SegmentationContent() {
  const { state: segmentationState } = useSegmentationStore();
  const { onSelectState, onReset, onSelectedMunicipality } = useSegmentation();
  // const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <SegmentationContainer>
      <GLTypography
        text="Segment your notification by applying location-based filtering."
        fontSize={4}
        color="FONT_COLOR"
        weight={400}
      />

      <div className="segmentation-content">
        <div className="segmentation-location">

          <div className="location-header">
            <GLTypography
              text="Select audience location"
              fontSize={5}
              color="FONT_COLOR"
              weight={600}
            />

            <GLTypography
              text="Choose at least one of the following locations"
              fontSize={4}
              color="FONT_COLOR"
              weight={400}
            />

          </div>
          {/*
          <div className="location-filters">
            <GLTextField placeholder="Search a location" title="" />
          </div> */}

          <div className="location-list">
            <ListMunicipalities />
          </div>
        </div>

        <div className="segmentation-map">
          <GLZoomPanPinch
            onReset={onReset}
            style={{ borderWidth: 0 }}
            showButtonBack={!!segmentationState.stateSelected}
          >
            <GLBrazilMap
              stateSelected={segmentationState.stateSelected}
              zoomOnSelect={2}
              municipalitiesSelecteds={segmentationState.municipalitiesSelected}
              wasClickedByMap={segmentationState.wasClickedByMap}
              onSelectMunicipality={onSelectedMunicipality}
              onSelectState={onSelectState}
              municipalitiesIsLoading={segmentationState.municipalitiesIsLoading}
              statesIsLoading={segmentationState.statesIsLoading}
            />
          </GLZoomPanPinch>
        </div>
      </div>

    </SegmentationContainer>
  );
}

export const Segmentation = memo(SegmentationContent);
