import {
  IRewritePassword,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useToast } from "@group-link-one/grouplink-components";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useForgotPasswordService } from "../../../Services/forgotPasswordService/useForgotServices";

export const useNewPassword = () => {
  const { t } = useI18n();
  useLocation();
  const { search } = useLocation();
  const { rewritePassword } = useForgotPasswordService();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const extractTokenFromSearch = () => {
    const params = new URLSearchParams(search);
    return params.get("token");
  };

  const rewrite = async ({ password }: IRewritePassword) => {
    try {
      const response = await rewritePassword({
        token: extractTokenFromSearch()!,
        password,
      });

      if (response.status === 202) {
        addToast({
          title: t("updatePasswordToast.title"),
          message: t("updatePasswordToast.description"),
          type: "success",
        });
        navigate("/");
      }
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError<Error>;

      if (
        error.response?.status === 400 ||
        error.response?.data.message === "token_expired| Token expired"
      ) {
        addToast({
          title: t("updatePasswordToast.error.title"),
          message: t("updatePasswordToast.error.description"),
          type: "error",
        });

        navigate("/forgot-password");

        return;
      }

      addToast({
        title: t("toast.error.title"),
        message: t("toast.error.message"),
        type: "error",
      });

      return;
    }
  };

  useEffect(() => {
    const hasToken = extractTokenFromSearch();

    if (!hasToken) {
      navigate("*");
    }
  }, []);

  return {
    rewrite,
  };
};
