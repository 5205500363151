import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  GetDevicesIDResponse,
  GetEventListParams,
  GetEventListResponse,
} from "./useEventListService.types";

export const useEventListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getEventList = async ({
    from,
    until,
    next_page_token,
    device_id,
    user_id,
  }: GetEventListParams): Promise<
    PaginateResponseProps<GetEventListResponse[]>
  > => {
    const formattedDeviceIds = device_id
      ?.map((id: string | number) => `device_id=${id}`)
      .join("&");

    const url = `/sr/event-list?from=${from}&until=${until}&ipp=15&user_id=${user_id}&next_page_token=${next_page_token ? next_page_token : ""}${formattedDeviceIds ? `&${formattedDeviceIds}` : ""}`;

    return (await httpPrivate.get(url)).data;
  };

  const getDevices = async (): Promise<GetDevicesIDResponse> => {
    return (await httpPrivate.get("/sr/device-name")).data;
  };

  return {
    getEventList,
    getDevices,
  };
};
